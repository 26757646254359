import Component from '../core/Component'
import PositionObserver from '../meta/PositionObserver'

export const STATES = {
    VISIBLE: 'has-displayed',
    WILL_INTERSECT: 'will-intersect'
}

const defaults = {
    offset: 0.5,
    once: true
}

export default class Intersection extends Component {
    constructor(element) {
        super(element)

        this.options = {
            ...defaults
        }

        if (this.element.dataset.options) {
            const options = JSON.parse(this.element.dataset.options)

            this.options = {
                ...this.options,
                ...options
            }
        }

        this.hasEntered = false
    }

    prepare() {
        this.element.classList.add(STATES.WILL_INTERSECT)
        this.element.offsetWidth
        this.observer = new PositionObserver(this.element, {
            onRender: ::this.handleRender
        })
    }

    destroy() {
        this.detach()
    }

    handleRender({ bodyRatio }) {
        if (bodyRatio > this.options.offset && !this.hasEntered) {
            this.handleEnter()
        }

        if (bodyRatio <= 0 && this.hasEntered) {
            this.handleLeave()
        }
    }

    handleEnter() {
        this.hasEntered = true
        this.element.classList.add(STATES.VISIBLE)

        if (this.options.once) {
            this.detach()
        }
    }

    handleLeave() {
        if (!this.options.once) {
            this.hasEntered = false
            this.element.classList.remove(STATES.VISIBLE)
        }
    }

    detach() {
        if (this.observer) {
            this.observer.destroy()
            this.observer = null
        }
    }

}