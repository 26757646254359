import Component from '../core/Component';
import { triggerEvent } from '../utils/dom';

export const STATUS = {
    FOCUSED: 'is-focused'
}

export default class NumberInput extends Component {

    constructor(element) {
        super(element);

        this.ref = {
            increment: null,
            decrement: null,
            input: null
        }
    }

    prepare() {
        this.ref.increment.addEventListener('click', ::this.handleIncrementClick);
        this.ref.decrement.addEventListener('click', ::this.handleDecrementClick);
        this.ref.input.addEventListener('keyup', ::this.handleChange);
        this.ref.input.addEventListener('focus', ::this.handleFocus);
        this.ref.input.addEventListener('blur', ::this.handleBlur);
    }

    handleChange() {
        if (parseInt(this.ref.input.value) < 0) {
            this.ref.input.value = 0;
        }
    }

    handleBlur() {
        this.element.classList.remove(STATUS.FOCUSED);
    }

    handleFocus() {
        this.element.classList.add(STATUS.FOCUSED);
    }

    handleIncrementClick(event) {
        event.preventDefault();
        event.stopPropagation();

        let value = parseInt(this.ref.input.value);

        if (isNaN(value)) {
            value = 0;
        }

        value++;

        this.ref.input.value = value;

        triggerEvent(this.ref.input, 'change');
    }

    handleDecrementClick(event) {
        event.preventDefault();
        event.stopPropagation();

        let value = parseInt(this.ref.input.value);

        if (isNaN(value)) {
            value = 0;
        }

        value--;

        if (value < 0) {
            value = 0;
        }

        this.ref.input.value = value;

        triggerEvent(this.ref.input, 'change');
    }
}