import enquire from 'enquire.js/src/index'

import Component from '../core/Component'
import { queries } from '../core/config'
import EventBus from '../core/EventBus'
import { disableScrolling, enableScrolling } from '../services/Scroll'

export default class MainVideo extends Component {
    constructor(element) {
        super(element);

        this.ref = {
            video: null,
        };

        this.video = this.ref.video;

        this.initialDone = false;
        this.isStretched = false

        this.hasAgePopup = document.documentElement.className.indexOf('has-age-popup') !== -1;

        this.enquireHandler = {
            match: () => {
                this.stretch();
                this.initialDone = true;
            }
        }

        this.mobileEnquireHandler = {
            match: () => {
                this.disable();
                this.initialDone = true;
            }
        }
    }

    prepare() {
        enquire.register(queries.xlargeUp, this.enquireHandler)
        enquire.register(queries.largeMax, this.mobileEnquireHandler)

        if (this.hasAgePopup) {
            EventBus.on('mainVideo:start', ::this.start);
        } else {
            setTimeout(() => {
                this.start();
            }, document.documentElement.className.indexOf('has-displayed-video') !== -1 ? 0 : 400);
        }
    }

    stretch() {
        if (!this.initialDone && document.documentElement.className.indexOf('has-displayed-video') === -1) {
            let box = this.element.getBoundingClientRect();

            this.element.style.top = `${-box.top}px`;
            this.element.style.left = `${-box.left}px`;
            this.element.style.width = `${window.innerWidth}px`;
            this.element.style.height = `${window.innerHeight}px`;
            this.isStretched = true
        }
    }

    start() {
        if (this.isStretched) {
            disableScrolling()
        }
        this.element.classList.add('is-playing');
        this.element.classList.add('is-animated');
        try {
            this.video.play();
        } catch (error) {
            //silent
            //kdyz uzivatel neinteraguje, tak na ios nejde pustit video
        }

        let time = 2000;
        let time2 = 2000 + 1200;
        if (document.documentElement.className.indexOf('has-displayed-video') !== -1) {
            time = 0;
            time2 = 0;
        }

        setTimeout(() => {
            this.element.style.top = "";
            this.element.style.left = "";
            this.element.style.width = "";
            this.element.style.height = "";
            this.element.classList.add('is-small');
        }, time);

        setTimeout(() => {
            document.documentElement.classList.remove('has-video-overlay');
            enableScrolling()
            EventBus.emit('mainVideo:playing');
        }, time2);

    }

    disable() {
        document.documentElement.classList.remove('has-video-overlay');
        this.element.style.display = "none";
        enableScrolling()
    }

}
