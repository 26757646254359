import support from '../utils/BrowserSupport';
import { query } from '../utils/dom';
import Scroll from '../services/Scroll'

const defaults = {
    root: window,
    spread: 30
}

export default class PositionObserver {

    constructor(element, options) {
        this.element = query(element);

        this.options = {
            ...defaults,
            ...options
        }

        this.box = null

        this.resize()
        this.attach()
    }

    attach() {
        Scroll.on('scroll', this.handleScroll)
        Scroll.on('resize', this.handleResize)
    }

    detach() {
        Scroll.off('scroll', this.handleScroll)
        Scroll.off('resize', this.handleResize)
    }

    handleResize = () => {
        this.resize()
    }

    handleScroll = ({ offset }) => {
        this.calculate(offset.y)
    }

    onRender(data) {
        if (this.options.onRender) {
            this.options.onRender(data)
        }
    }

    onEnter(data) {
        if (this.options.onEnter) {
            this.options.onEnter(data);
        }
    }

    onLeave(data) {
        if (this.options.onLeave) {
            this.options.onLeave(data);
        }
    }

    destroy() {
        this.detach()
    }

    calculate(scrollTop) {
        if (this.defaultBox) {
            let ratio = 0
            let bodyRatio = 0

            if (scrollTop < this.min) {
                ratio = -1;
            } else if (scrollTop > this.max) {
                ratio = 1;
            } else {
                ratio = (scrollTop - this.min)/(this.max - this.min) * 2 - 1;
            }

            if (ratio === 1 || ratio === -1) {
                if (this.active) {
                    this.active = false;
                    this.onLeave({
                        scrollX: 0,
                        scrollY: scrollTop,
                        ratio,
                        bodyRatio
                    });
                }
            } else {
                if (!this.active) {
                    this.active = true;
                    this.onEnter({
                        scrollX: 0,
                        scrollY: scrollTop,
                        ratio: 0,
                        bodyRatio
                    });
                }
            }

            if (scrollTop < this.min || scrollTop > this.max) {
                bodyRatio = 0
            } else if (this.defaultBox.top - scrollTop > 0) {
                bodyRatio = Math.max(Math.min((this.defaultBox.height + (this.rootHeight - (this.defaultBox.top - scrollTop + this.defaultBox.height)))/this.defaultBox.height, 1), 0)
            } else {
                bodyRatio = Math.max(1 - (scrollTop - this.defaultBox.top)/this.defaultBox.height, 0)
            }

            this.onRender({
                scrollX: 0,
                scrollY: scrollTop,
                ratio,
                bodyRatio
            });
        } else {
            this.onRender({
                scrollX: 0,
                scrollY: scrollTop,
                ratio: 0,
                bodyRatio: 0
            });
        }
    }

    resize() {
        this.baseOffset = null;

        if (this.options.onBeforeResize) {
            this.options.onBeforeResize()
        }

        const box = this.element.getBoundingClientRect();
        const scrollTop = Scroll.getScroll().y
        const top = box.top + scrollTop;
        const bottom = box.bottom + scrollTop;

        const rootHeight = this.options.root.innerHeight || this.options.root.clientHeight;
        const rootHeightHalf = rootHeight/2;

        const min = top - rootHeight - this.options.spread;
        const max = bottom + this.options.spread;
        const center = Math.round((max - min)/2 + min);

        this.defaultBox = {
            top: top,
            bottom: bottom,
            left: box.left,
            right: box.right,
            width: box.width,
            height: box.height
        }

        this.rootHeight = rootHeight;
        this.rootHeightHalf = rootHeightHalf;
        this.min = min;
        this.max = max;

        if (scrollTop < this.min) {
            this.calculate(this.min)
        } else if (scrollTop > this.max) {
            this.calculate(this.max);
        } else {
            this.calculate(scrollTop)
        }
    }

}