import Component from '../core/Component'
import flatpickr from "flatpickr";

export default class Datepicker extends Component {

    prepare() {
        this.flatpickr = flatpickr(this.element, {});
    }

    destroy() {
        this.flatpickr.destroy();
    }

}