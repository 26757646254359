import Component from '../core/Component'
import { toggleClass } from '../utils/dom'

export const STATES = {
    FOCUSED: 'is-focused',
    EMPTY: 'is-empty'
}

export default class Input extends Component {

    constructor(element) {
        super(element)

        this.ref = {
            input: null
        }
    }

    prepare() {
        this.ref.input.addEventListener('focus', ::this.handleFocus)
        this.ref.input.addEventListener('blur', ::this.handleBlur)
        this.ref.input.addEventListener('keyup', ::this.handleKeyUp)
        this.check()
    }

    handleFocus() {
        this.element.classList.add(STATES.FOCUSED)
    }

    handleBlur() {
        this.element.classList.remove(STATES.FOCUSED)
        this.check()
    }

    handleKeyUp() {
        this.check()
    }

    check() {
        toggleClass(this.element, STATES.EMPTY, this.ref.input.value === '')
    }

}