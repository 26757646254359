import Component from '../core/Component'
import Flickity from 'flickity'
import throttle from 'lodash/throttle'
import debounce from 'lodash/debounce'

export const STATUS = {
    READY: 'is-ready',
}

export const defaults = {
    autoplay: false,
    wrapAround: true,
    prevNextButtons: false,
    pageDots: false,
    resize: true,
}

export default class Slider extends Component {
    constructor(element, options = {}) {
        super(element, defaults)

        this.ref = {
            content: null,
            slides: [],
            buttonNext: null,
            buttonPrev: null,
        }

        this.state = {
            dragging: false,
            current: 0,
            timer: null,
        }

        this.options = options

        this.handleResize = throttle(this.handleResize, 100)
        this.handleWindowBlur = debounce(this.handleWindowBlur, 50)
        this.handleWindowFocus = debounce(this.handleWindowFocus, 50)
    }

    prepare() {
        this.createSlider()

        this.element.addEventListener('mouseenter', this.handleMouseEnter)
        this.element.addEventListener('mouseleave', this.handleMouseLeave)
        this.element.addEventListener('click', this.handleClick)
        // this.element.addEventListener('touchstart', this.handleTouchStart)
        // this.element.addEventListener('touchmove', this.handleTouchMove)
        // this.element.addEventListener('touchend', this.handleTouchEnd)

        window.addEventListener('resize', this.handleResize)
        window.addEventListener('blur', this.handleWindowBlur)
        window.addEventListener('focus', this.handleWindowFocus)

        if (this.options.autoplay) {
            this.start()
        }
    }

    destroy() {
        this.stop()
        window.removeEventListener('resize', this.handleResize)
        window.removeEventListener('blur', this.handleWindowBlur)
        window.removeEventListener('focus', this.handleWindowFocus)
    }

    shouldCreateSlider = () => {
        return this.ref.slides.length > 1
    }

    createSlider = () => {
        if (!this.shouldCreateSlider()) {
            return
        }

        this.element.classList.add(STATUS.READY)

        const { autoplay, ...options } = this.options

        this.flickity = new Flickity(this.ref.content, {
            cellSelector: '[data-ref="slide"]',
            ...options,
            resize: false,
        })

        this.ref.buttonNext?.addEventListener('click', this.handleClickNext)
        this.ref.buttonPrev?.addEventListener('click', this.handleClickPrevious)

        this.flickity.on('dragStart', this.handleDragStart)
        this.flickity.on('dragEnd', this.handleDragEnd)
        this.flickity.on('settle', this.handleSettle)
    }

    destroySlider = () => {
        if (!this.flickity) {
            return
        }

        this.element.classList.remove(STATUS.READY)

        this.flickity.destroy()
        this.ref.buttonNext?.removeEventListener('click', this.handleClickNext)
        this.ref.buttonPrev?.removeEventListener('click', this.handleClickPrevious)
    }

    handleClickNext = (event) => {
        event.preventDefault()
        this.stop()
        this.flickity.next()
        if (autoplay) {
            this.start()
        }
    }

    handleClickPrevious = (event) => {
        event.preventDefault()
        this.stop()
        this.flickity.previous()
        if (autoplay) {
            this.start()
        }
    }

    handleDragStart = () => {
        document.ontouchmove = () => false
        this.state.dragging = true
        this.stop()
    }

    handleDragEnd = () => {
        document.ontouchmove = () => true
        if (this.options.autoplay) {
            this.start()
        }
    }

    handleSettle = () => {
        this.state.dragging = false
    }

    handleClick = (event) => {
        if (this.state.dragging) {
            event.stopPropagation()
            event.preventDefault()
        }
    }

    handleMouseEnter = () => {
        this.stop()
    }

    handleMouseLeave = () => {
        if (this.options.autoplay) {
            this.start()
        }
    }

    handleWindowFocus = (event) => {
        if (this.options.autoplay) {
            this.start()
        }
    }

    handleWindowBlur = (event) => {
        this.stop()
    }

    handleResize = () => {
        this.resize()
    }

    resize() {
        if (this.flickity && !this.state.dragging) {
            this.flickity.resize()
        }
    }

    stop() {
        clearInterval(this.state.timer)
    }

    start() {
        this.stop()
        this.state.timer = setInterval(this.next, 5000)
    }

    next = () => {
        this.flickity?.next()
    }
}
