import Component from '../core/Component'
import flatpickr from 'flatpickr'
import { German } from 'flatpickr/dist/l10n/de.js'
import { Czech } from 'flatpickr/dist/l10n/cs.js'
import Scroll, { scrollTo } from '../services/Scroll'

export const STATE = {
    FOCUSED: 'is-focused',
}

export default class FilterField extends Component {
    constructor(element) {
        super(element)

        this.ref = {
            input: null,
        }

        this.state = {
            availabilityButtons: [],
        }

        try {
            this.options = JSON.parse(this.element.getAttribute('data-options'))
        } catch (error) {
            this.options = {
                limit: null,
            }
        }
    }

    prepare() {
        this.ref.input.addEventListener('focus', this.handleFocus)
        this.ref.input.addEventListener('blur', this.handleBlur)

        const lang = document.documentElement.getAttribute('lang')
        const langs = {
            cs: Czech,
            de: German,
        }

        if (this.ref.input.type == 'date' || this.ref.input.type == 'text') {
            let options = {}
            if (this.options.limit === 'today') {
                options = {
                    minDate: 'today',
                }
            } else if (this.options.limit === 'tomorrow') {
                options = {
                    minDate: new Date().fp_incr(1),
                }
            }

            if (lang !== 'en') {
                options.locale = langs[lang]
            }
            this.flatpickr = flatpickr(this.ref.input, options)
        }
    }

    focus() {
        const offset =
            this.element.getBoundingClientRect().top +
            Scroll.getScroll().y -
            (window.innerHeight * 1) / 3
        scrollTo(offset, {
            callback: () => {
                this.ref.input.focus()
            },
        })
    }

    destroy() {
        this.flatpickr.destroy()
    }

    handleFocus = () => {
        this.element.classList.add(STATE.FOCUSED)
    }

    handleBlur = () => {
        this.element.classList.remove(STATE.FOCUSED)
    }
}
