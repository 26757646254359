import Component from '../core/Component';
import GoogleMapsLoader from 'google-maps';
import throttle from 'lodash/throttle';
import { createMarker } from './Marker'
GoogleMapsLoader.KEY = 'AIzaSyDNOX-Un_5daBZwGNoCobUg3gCm4qG_jyA';
GoogleMapsLoader.VERSION = 3.38

export default class GoogleMap extends Component {

    constructor(element) {
        super(element);

        this.latlng = null;
        this.isReady = false;

        this.handleResize = throttle(::this.center, 200)
    }

    prepare() {
        this.coords =  {
            lat: this.element.dataset.lat,
            lng: this.element.dataset.lng
        }

        GoogleMapsLoader.load(::this.render);

        window.addEventListener('resize', this.handleResize)
        window.addEventListener('orientationchange', this.handleResize)
    }

    destroy() {
        this.map = null;
        window.removeEventListener('resize', this.handleResize)
        window.removeEventListener('orientationchange', this.handleResize)
    }

    render(google) {
        const latlng = new google.maps.LatLng(this.coords.lat, this.coords.lng);
        this.google = google;
        this.latlng = latlng;

        this.map = new google.maps.Map(this.element, {
            center: latlng,
            zoom: 14,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            zoomControl: false,
            scaleControl: false,
            fullscreenControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            styles: [
                {
                    "stylers": [
                        {
                            "hue": "#ff1a00"
                        },
                        {
                            "invert_lightness": true
                        },
                        {
                            "saturation": -100
                        },
                        {
                            "lightness": 33
                        },
                        {
                            "gamma": 0.5
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#2D333C"
                        }
                    ]
                }
            ]
        });
        const Marker = createMarker(google);

        var marker = new Marker({
            position: latlng,
            map: this.map
        });

        google.maps.event.addListenerOnce(this.map, 'idle', () => {
            this.isReady = true;
            this.center();
        });

        // setTimeout(() => {
        //     this.isReady = true;
        //     this.center();
        // }, 0);
    }

    center() {
        if (this.isReady) {
            this.google.maps.event.trigger(this.map,'resize');
            this.mapRecenter(this.map, this.latlng, 0, 20);
        }
    }

    mapRecenter(map, latlng, offsetx, offsety) {
        var point1 = map.getProjection().fromLatLngToPoint(
            (latlng instanceof google.maps.LatLng) ? latlng : map.getCenter()
        );
        var point2 = new google.maps.Point(
            ( (typeof(offsetx) == 'number' ? offsetx : 0) / Math.pow(2, map.getZoom()) ) || 0,
            ( (typeof(offsety) == 'number' ? offsety : 0) / Math.pow(2, map.getZoom()) ) || 0
        );
        map.setCenter(map.getProjection().fromPointToLatLng(new google.maps.Point(
            point1.x - point2.x,
            point1.y + point2.y
        )));
    }
}
