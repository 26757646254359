import Component from '../core/Component'
import { scrollToElement } from '../services/Scroll'

const SELECTOR = 'a[href*="#"]:not([href="#todo"]):not([data-ref])'
export default class Page extends Component {
    prepare() {
        this.element.addEventListener('click', this.handleClick)
    }

    handleClick = event => {
        if (event.defaultPrevented) {
            return
        }

        const id = event.target.closest(SELECTOR)?.href?.split('#')[1]

        if (!id) {
            return
        }

        const element = document.getElementById(id)

        if (!element) {
            return
        }

        event.preventDefault()

        if (element.matches('.Section:not(.Section--appended):not(.Section--bare)')) {
            scrollToElement(element, {
                offset: 0
            })
        } else {
            scrollToElement(element)
        }
    }
}
