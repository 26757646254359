import Component from '../core/Component'
import Cookies from 'js-cookie'
import support from '../utils/BrowserSupport'

export const STATES = {
    ACTIVE: 'is-active',
    VISIBLE: 'is-visible',
}

export default class ModalAlert extends Component {
    constructor(element, options = {}) {
        super(element, options)

        this.ref = {
            layer: null,
            close: null
        }

        this.alertId = null
    }

    prepare() {
        this.alertId = this.element.dataset.alertId
        const alertId = Cookies.get(`modalAlert-${this.alertId}`)

        if (!alertId || alertId !== this.alertId) {
            this.ref.close.addEventListener('click', this.handleClick)
            this.show()
        } else {
            this.selfRemove()
        }
    }

    destroy() { }

    handleClick = event => {
        // event.preventDefault()
        this.hide()
    }

    show() {
        this.element.classList.add(STATES.VISIBLE)
        this.element.offsetWidth
        this.element.classList.add(STATES.ACTIVE)

        this.ref.layer.addEventListener(support.transitionEnd, () => {
            if (!this.is(STATES.ACTIVE)) {
                this.selfRemove()
            }
        })
    }

    hide() {
        this.element.classList.remove(STATES.ACTIVE)

        Cookies.set(`modalAlert-${this.alertId}`, this.alertId, {
            expires: 30
        });
    }

    selfRemove() {
        this.element.parentNode.removeChild(this.element)
    }
}