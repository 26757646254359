import Component from '../core/Component'
import EventBus from '../core/EventBus'
import { enableScroll, disableScroll } from '../services/Scroll'
import support from '../utils/BrowserSupport'
import keycode from 'keycode'
import { removeComponents, loadComponents } from '../core/componentUtils'

export const STATES = {
    ACTIVE: 'is-active',
    VISIBLE: 'is-visible',
    LOADING: 'is-loading',
    FULLSCREEN: 'is-fullscreen'
}

export const EMITS = {
    OPEN: 'open',
    CLOSE: 'close'
}

export default class Modal extends Component {
    constructor(element, options = {}) {
        super(element, {
            dispose: false,
            url: null,
            className: '',
            fullscreen: false,
            netteCloseOn: null,
            autoOpen: false,
            closeOnOuterClick: true,
            filterResponse: function(html) { return html }
        })

        this.ref = {
            background: null,
            layer: null,
            content: null,
            closeButtons: [],
            text: null
        }

        this.options = options

        if (this.options.className) {
            this.element.classList.add(this.options.className)
        }

        if (this.options.fullscreen) {
            this.element.classList.add(STATES.FULLSCREEN)
        }

        if (this.options.content) {
            this.setContent(this.options.content)
        }

        this.handleKeyDown = ::this.handleKeyDown
        this.request = null
    }

    prepare() {
        this.ref.closeButtons.forEach(closeButton => closeButton.addEventListener('click', this.handleCloseClick))

        this.ref.layer.addEventListener(support.transitionEnd, event => {
            if (this.ref.layer === event.target && !this.is(STATES.ACTIVE)) {
                this.element.classList.remove(STATES.VISIBLE)

                if (this.options.dispose) {
                    removeComponents(this.ref.text)
                    this.destroy()
                    this.element.parentNode.removeChild(this.element)
                    this.element = null
                }
            }
        })

        if (this.options.closeOnOuterClick) {
            this.element.addEventListener('click', ::this.handleOuterClick)
        }


        if (this.options.autoOpen && this.options.url) {
            this.open()
            this.load()
        }

        setTimeout(() => {
            const id = window.location.hash.replace('#','')

            if (id === this.element.id) {
                this.open()
            }
        }, 0);
    }

    destroy() {
        document.removeEventListener('keydown', this.handleKeyDown)
        if (this.request) {
            this.request.abort()
        }
    }

    handleCloseClick = event => {
        event.preventDefault()
        this.close()
    }

    handleOuterClick(event) {
        if (!this.ref.content.contains(event.target)) {
            this.close()
        }
    }

    handleClick(event) {
        event.preventDefault()
        this.close()
    }

    handleKeyDown(event) {
        if (keycode(event.keyCode) === 'esc') {
            this.close()
        }
    }

    handleNetteEvent = data => {
        const closeOn = JSON.stringify(this.options.netteCloseOn)
        const action = JSON.stringify(data)

        if (closeOn === action) {
            this.close()
        }
    }

    open(data = {}) {
        if (this.is(STATES.ACTIVE)) {
            return
        }
        disableScroll()
        this.element.classList.add(STATES.VISIBLE)
        this.element.offsetWidth
        this.element.classList.add(STATES.ACTIVE)

        document.addEventListener('keydown', this.handleKeyDown)

        this.emit('open', {
            id: this.element.id,
            data
        })

        EventBus.emit('modal:open', {
            id: this.element.id,
            data
        })

        if (this.options.url && !this.options.autoOpen) {
            this.load()
        }

        EventBus.on('nette:event', this.handleNetteEvent)

    }

    close() {
        enableScroll()
        this.element.classList.remove(STATES.ACTIVE)
        document.removeEventListener('keydown', this.handleKeyDown)

        this.emit('close', {
            id: this.element.id
        })

        EventBus.emit('modal:close', {
            id: this.element.id
        })

        if (this.options.netteCloseOn) {
            EventBus.off('nette:event', this.handleNetteEvent)
        }
    }

    load() {
        this.element.classList.add(STATES.LOADING)
        $.ajax({
            url: this.options.url,
            dataType: 'HTML',
            success: response => {
                this.setContent(this.options.filterResponse(response))
            },
            complete: () => {
                this.element.classList.remove(STATES.LOADING)

                if (this.options.autoOpen) {
                    this.open()
                }
            }
        })
    }

    setContent(html) {
        removeComponents(this.ref.text)
        this.ref.text.innerHTML = html
        loadComponents(this.ref.text)
        $.nette.load()
    }
}

const template = `
    <div class="Modal-background" data-ref="background"></div>
    <div class="Modal-layer" data-ref="layer">
        <div class="Modal-inner">
            <div class="Modal-content" data-ref="content">
                <div class="Modal-text" data-ref="text">
                </div>
                <button type="button" class="Modal-close" data-ref="closeButton">Zavřít</button>
            </div>
        </div>
    </div>
`

export function createModal(options) {
    const element = document.createElement('div')
    element.classList.add('Modal')
    element.innerHTML = template
    document.body.appendChild(element)

    const modal = new Modal(element, {
        dispose: true,
        ...options
    })
    modal.prepare()

    return modal
}
