import Component from '../core/Component'
import EventBus from '../core/EventBus'

export default class ResultsTable extends Component {

    constructor(element) {
        super(element)
        this.request = null
    }

    prepare() {
        this.element.addEventListener('click', this.handleClick)
    }

    handleClick = (event) => {
        const row = event.target.closest('tr')

        if (!row) {
            return
        }

        const target = row.querySelector('a')

        if (!target) {
            return
        }
        event.preventDefault()

        EventBus.emit('result:detail:click', {
            url: target.href
        })
    }

}
