import Component from '../core/Component.js'
import Flickity from 'flickity'
import enquire from 'enquire.js'
import { queries } from '../core/config'
import { toggleClass } from '../utils/dom'
import InputResolver from '../meta/TouchMouseInputResolver'

export const defaults = {
    cellSelector: '[data-ref="item"]',
    prevNextButtons: false,
    pageDots: false,
    cellAlign: 'left',
    autoplay: true,
    lazyLoad: 2,
    responzive: true,
    resize: true,
    percentPosition: true,
    selectedAttraction: 0.1,
    friction: 0.8,
    contain: true,
    media: '',
    followPointerType: true,
}

export const STATES = {
    READY: 'is-ready',
    DISABLED: 'is-disabled',
}

export default class SideSlider extends Component {
    constructor(element, options = {}) {
        super(element, defaults)

        this.ref = {
            slider: null,
            items: [],
            prev: null,
            next: null,
        }

        this.options = options
        this.flickity = null
        this.index = 0
        this.isDragging = false
        this.dragEndTimer = null

        this.enquireHandler = null

        if (this.options.media) {
            this.enquireQuery = this.options.media in queries ? queries[this.options.media] : this.options.media
            this.enquireHandler = {
                match: this.match,
                unmatch: this.unmatch,
            }
        }
    }

    prepare() {
        if (this.enquireHandler) {
            enquire.register(this.enquireQuery, this.enquireHandler)
        } else if (this.options.followPointerType) {
            InputResolver.on('change', this.handlePointerTypeChange)
            if (InputResolver.current === InputResolver.TOUCH) {
                this.attach()
            }
        } else {
            this.attach()
        }

        this.ref.slider.addEventListener('click', this.handleClick)

        if (this.element.offsetWidth > this.ref.items.length * 210) {
            this.ref.prev.classList.add(STATES.DISABLED)
            this.ref.next.classList.add(STATES.DISABLED)
        }
    }

    destroy() {
        if (this.enquireHandler) {
            enquire.unregister(this.enquireQuery, this.enquireHandler)
        } else {
            this.detach()
        }
    }

    match = () => {
        if (this.options.followPointerType) {
            InputResolver.on('change', this.handlePointerTypeChange)
            if (InputResolver.current === InputResolver.TOUCH) {
                this.attach()
            }
        } else {
            this.attach()
        }
    }

    unmatch = () => {
        InputResolver.off('change', this.handlePointerTypeChange)
        this.detach()
    }

    attach() {
        if (this.flickity) {
            return
        }

        this.element.classList.add(STATES.READY)

        this.flickity = new Flickity(this.ref.slider, this.options)

        this.ref.prev?.addEventListener('click', this.handlePreviousClick)
        this.ref.next?.addEventListener('click', this.handleNextClick)

        this.flickity.on('change', this.handleChange)
        this.flickity.on('dragStart', this.handleDragStart)
        this.flickity.on('dragEnd', this.handleDragEnd)
    }

    detach() {
        if (this.flickity) {
            this.flickity.destroy()
            this.flickity = null
        }

        this.element.classList.remove(STATES.READY)

        this.ref.prev?.removeEventListener('click', this.handlePreviousClick)
        this.ref.next?.removeEventListener('click', this.handleNextClick)
    }

    handlePointerTypeChange = ({ type }) => {
        if (type === InputResolver.TOUCH) {
            this.attach()
        } else if (type === InputResolver.MOUSE) {
            this.detach()
        }
    }

    handleClick = (event) => {
        if (this.isDragging) {
            event.stopPropagation()
            event.preventDefault()
        }
    }

    handleDragStart = () => {
        clearTimeout(this.dragEndTimer)
        this.isDragging = true

        this.ref.items.forEach(item => {
            item.style.pointerEvents = 'none'
        })
    }

    handleDragEnd = () => {
        clearTimeout(this.dragEndTimer)
        this.dragEndTimer = setTimeout(() => {
            this.ref.items.forEach(item => {
                item.style.pointerEvents = 'auto'
            })
        }, 100)
    }

    handleNextClick = (event) => {
        event.preventDefault()
        this.next()
    }

    handlePreviousClick = (event) => {
        event.preventDefault()
        this.previous()
    }

    handleChange = (index) => {
        const spaceLeft = this.flickity.slideableWidth - this.flickity.size.width
        const pos = index > this.index ? Math.abs(this.flickity.slides[index].x) + this.flickity.slides[index].outerWidth : 0
        toggleClass(this.ref.prev, STATES.DISABLED, index === 0)
        toggleClass(this.ref.next, STATES.DISABLED, pos >= spaceLeft + 40)

        this.index = index
    }

    next() {
        this.flickity.next()
    }

    previous() {
        this.flickity.previous()
    }
}
