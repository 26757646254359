import Component from "../core/Component"

export default class PokerStream extends Component {
    constructor(element, options = {}) {
        super(element, options)

        const createVideoElement = () => {
            const video = document.createElement("video")
            video.muted = true
            video.setAttribute('muted', '')
            video.setAttribute('playsinline', '')
            this.element.appendChild(video)
            video.addEventListener("ended", this.handleVideoEnd)
            return video
        }

        this.ref = {
            video1: null,
            video2: null,
        }

        this.ref.video1 = createVideoElement()
        this.ref.video2 = createVideoElement()

        this.currentVideo = null
        this.lastPlayedFile = null
        this.bufferedFiles = []
        this.isForcePlay = false
        this.waitingTimer = null
    }

    prepare() {
        //sam se neumi updatovat, je potreba na nem furt volat addFile (prip. play())
    }

    handleVideoEnd = () => {
        this.isPlaying = false
        this.play()
    }

    addFile(file) {
        if (file === this.getLastFile() || !file) {
            return
        }

        this.bufferedFiles.push(file)
        this.play()
    }

    play() {
        if (this.bufferedFiles.length > 3) {
            this.setPlaybackRate(1.4)
        } else if (this.bufferedFiles.length > 2) {
            this.setPlaybackRate(1.2)
        } else if (this.bufferedFiles.length < 1) {
            this.setPlaybackRate(0.8)
        } else {
            this.setPlaybackRate(1)
        }

        if (this.isPlaying) {
            return
        }

        if (this.bufferedFiles.length < 2 && !this.isForcePlay) {
            if (!this.waitingTimer) {
                //pocka 10s jestli nahodou neprijde dalsi chunk, pokud neprijde, tak ho pusti (asi to byl posledni a stream jeste neni vypnutej)
                this.waitingTimer = setTimeout(() => {
                    this.isForcePlay = true
                    this.play()
                }, 5000)
                this.element.classList.add('is-loading')
            }
            console.log('abort')
            return
        }
        clearTimeout(this.waitingTimer)
        this.element.classList.remove('is-loading')

        this.previousVideo = this.currentVideo
        this.currentVideo =
            this.currentVideo === this.ref.video1
                ? this.ref.video2
                : this.ref.video1
        this.currentVideo.classList.add("is-visible")
        this.previousVideo?.classList.remove("is-visible")

        const file = this.bufferedFiles.shift()
        this.currentVideo.src = file
        this.lastPlayedFile = file
        this.isPlaying = true
        this.isForcePlay = false
        this.currentVideo.play().catch((error) => {
            this.isPlaying = false
            // console.error(error)
        })

        setTimeout(() => {
            if (this.ref.video1 !== this.currentVideo) {
                this.ref.video1.parentNode.removeChild(this.ref.video1)
                this.ref.video1 = this.createVideoElement()
            }
            if (this.ref.video2 !== this.currentVideo) {
                this.ref.video2.parentNode.removeChild(this.ref.video2)
                this.ref.video2 = this.createVideoElement()
            }
        }, 2500)
    }

    getLastFile() {
        return this.bufferedFiles.length
            ? this.bufferedFiles[this.bufferedFiles.length - 1]
            : this.lastPlayedFile
    }

    setPlaybackRate(playbackRate = 1) {
        if (!this.currentVideo) {
            return
        }
        this.currentVideo.playbackRate = playbackRate
    }

    createVideoElement() {
        const video = document.createElement("video")
        video.muted = true
        video.setAttribute('muted', '')
        video.setAttribute('playsinline', '')
        this.element.appendChild(video)
        video.addEventListener("ended", this.handleVideoEnd)
        return video
    }
}
