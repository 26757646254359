import Component from '../core/Component'

const STATES = {
    ACTIVE: 'is-active',
    READY: 'is-ready'
}

const defaults = {}

class AccordionItem extends Component {

    constructor(element, options) {
        super(element)

        this.ref = {
            toggle: null,
            block: null
        }

        this.options = {
            ...defaults,
            ...options
        }

        this.ref.toggle.addEventListener('click', ::this.handleClick)
    }

    handleClick(event) {
        event.preventDefault()

        if (this.is(STATES.ACTIVE)) {
            this.close()
        } else {
            this.open()
        }
    }

    open() {
        this.element.classList.add(STATES.ACTIVE)

        if (this.options.onOpen) {
            this.options.onOpen(this)
        }
    }

    close() {
        this.element.classList.remove(STATES.ACTIVE)

        if (this.options.onClose) {
            this.options.onClose(this)
        }
    }

}

export default class Accordion extends Component {

    constructor(element) {
        super(element)

        this.ref = {
            items: []
        }

        this.items = []
    }

    prepare() {
        this.items = this.ref.items.map(item => new AccordionItem(item, {
            onOpen: ::this.handleItemOpen
        }))

        this.element.classList.add(STATES.READY)
    }

    handleItemOpen(currentItem) {
        this.items
            .filter(item => item !== currentItem)
            .forEach(item => item.close())
    }

}