import Component from "../core/Component"
import { EMITS as POKERSTREAMPOLLER_EMITS } from "./PokerStreamPoller"
import PokerStream from "./PokerStream"

export default class PokerStreamList extends Component {
    constructor(element, options = {}) {
        super(
            element,
            (options = {
                pollerQuery: "#poker-stream-poller",
                maxStreamCount: 0,
                ...options,
            })
        )

        this.ref = {
            list: null,
            streamTemplate: null,
        }

        this.isFirstRender = true
        this.streamTemplate = this.ref.streamTemplate.innerText.trim()
        this.streams = {}
    }

    prepare() {
        //swup konteinery loaduje postupne, prepare pokerstreamlist se stihne pustit jeste pred initnutim pokerstreampoller...
        setTimeout(() => {
            const pollerEl = document.querySelector(this.options.pollerQuery)
            if (pollerEl) {
                this.poller = Component.getFromElement(pollerEl)
                if (this.poller) {
                    this.poller.on(
                        POKERSTREAMPOLLER_EMITS.MESSAGE,
                        this.handlePollerMessage
                    )
                }
            }
        }, 0);

        document.addEventListener(
            "visibilitychange",
            this.handleDocumentVisibilityChange
        )
    }

    destroy() {
        if (this.poller) {
            this.poller.off(
                POKERSTREAMPOLLER_EMITS.MESSAGE,
                this.handlePollerMessage
            )
        }
        document.removeEventListener(
            "visibilitychange",
            this.handleDocumentVisibilityChange
        )
    }

    handlePollerMessage = (message) => {
        if (this.isFirstRender) {
            this.isFirstRender = false
            this.ref.list.innerHTML = ""
        }

        const { streams: streamDatas } = message

        const currentStreams = Object.keys(this.streams)
        const incomingStreams = Object.keys(streamDatas)

        //odmazat nepouzivany streamy
        let newStreams = incomingStreams.filter(
            (stream) => !currentStreams.includes(stream)
        )
        const deletedStreams = currentStreams.filter(
            (stream) => !incomingStreams.includes(stream)
        )
        deletedStreams.forEach((streamId) => {
            this.removeStream(streamId)
        })

        //pridat novy streamy
        const maxStreamCount = this.options.maxStreamCount
        const newCount =
            currentStreams.length - deletedStreams.length + newStreams.length
        if (maxStreamCount && newCount > maxStreamCount) {
            newStreams.length = maxStreamCount - currentStreams.length
        }
        newStreams.forEach((streamId) => {
            this.addStream(streamId, streamDatas[streamId])
        })

        //aktualizovat
        Object.entries(this.streams).forEach(([streamId, stream]) => {
            const streamData = streamDatas[streamId]
            stream.pokerStream.addFile(streamData.file)
        })
    }

    handleDocumentVisibilityChange = () => {
        if (document.hidden) {
            Object.values(this.streams).forEach(stream => {
                stream.pokerStream.element.classList.add('is-loading')
            })
        }
    }

    removeStream(streamId) {
        const stream = this.streams[streamId]
        if (!stream) {
            return
        }

        stream.pokerStream.destroy()
        stream.itemEl.parentNode.removeChild(stream.itemEl)
        delete this.streams[streamId]
    }

    addStream(streamId, streamData) {
        const div = document.createElement("div")
        div.innerHTML = this.streamTemplate
        const itemEl = div.querySelector("[data-item]")
        if (!itemEl) {
            return
        }

        const pokerStreamEl = div.querySelector("[data-poker-stream]")
        if (!pokerStreamEl) {
            return
        }

        const pokerStream = new PokerStream(pokerStreamEl)
        pokerStream.prepare()
        pokerStream.addFile(streamData.file)

        this.ref.list.appendChild(itemEl)
        this.streams[streamId] = {
            itemEl,
            pokerStream,
        }
    }
}
