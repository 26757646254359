import bounty from 'bounty';

import Component from '../core/Component';

export default class JackpotBar extends Component {

    constructor(element) {
        super(element)

        this.ref = {
            time: null,
            jackpots: [],
        }

        this.updateCounter = 0
        this.updateTime = new Date(0)
        this.odometers = []
        this.timeoutId = null
    }

    prepare() {
        this.jackpots = this.ref.jackpots.reduce((acc, jackpotElement) => {
            const jackpotName = jackpotElement.dataset.name
            acc[jackpotName] = {
                element: jackpotElement,
                number: jackpotElement.querySelector('[data-jackpot-number]'),
            }
            return acc
        }, {})

        this.loadJackpots()
    }

    destroy() {
        this.jackpots = null
        this.clearTimeout()
    }

    async loadJackpots() {
        try {
            const response = await fetch('/task/jackpot/bar')
            const json = await response.json()

            const newUpdateTime = new Date(json.time)

            if (newUpdateTime.getTime() !== this.updateTime.getTime()) {
                this.updateTime = newUpdateTime

                const hours = ('0' + this.updateTime.getHours()).slice(-2)
                const minutes = ('0' + this.updateTime.getMinutes()).slice(-2)
                const timeString = `${hours}:${minutes}`
                this.ref.time.innerText = timeString;

                Object.keys(json.items).forEach(jackpotName => {
                    const jackpot = json.items[jackpotName]
                    if (!this.jackpots[jackpotName]) return

                    const jackpotNumberElement = this.jackpots[jackpotName].number
                    const formattedValue = jackpot.value.toLocaleString()
                    jackpotNumberElement.setAttribute('data-number', jackpot.value)

                    if (!this.odometers[jackpotName]) {
                        this.odometers[jackpotName] = bounty({
                            el: jackpotNumberElement,
                            value: formattedValue,
                            lineHeight: 1,
                            duration: 2000
                        })
                    } else {
                        this.odometers[jackpotName].cancel()
                        const oldValue = jackpotNumberElement.getAttribute('data-number')
                        const oldFormattedValue = oldValue.toLocaleString()

                        this.odometers[jackpotName] = bounty({
                            el: jackpotNumberElement,
                            initialValue: oldValueFormatted,
                            value: formattedValue,
                            lineHeight: 1,
                            duration: 2000
                        })

                        this.hideLeadingDigits(jackpotNumberElement, oldFormattedValue, formattedValue)
                    }
                })

                this.updateCounter = 0
            }

            this.update()

        } catch (err) {
            console.error(err)
        }
    }

    update() {
        this.clearTimeout()

        if (this.updateCounter < 1) {
            this.updateCounter++
            const currentTime = new Date()
            const timeDiff = Math.abs(this.updateTime - currentTime)
            const diffSeconds = Math.floor(timeDiff / 1000)
            let timeLeftInSeconds = 360 - diffSeconds

            if (timeLeftInSeconds <= 0) {
                timeLeftInSeconds = 15
            }

            this.timeoutId = setTimeout(() => {
                this.loadJackpots()
            }, timeLeftInSeconds * 1000)
        }
        else {
            this.updateCounter = 0

            this.timeoutId = setTimeout(() => {
                this.update()
            }, 6 * 60 * 1000)
        }
    }

    clearTimeout() {
        if (this.timeoutId !== null) {
            clearTimeout(this.timeoutId)
            this.timeoutId = null
        }
    }

    hideLeadingDigits(jackpotNumberElement, oldValue, newValue) {
        const digits = oldValue.toString().replace(/\s/g, '')
        const newDigits = newValue.toString().replace(/\s/g, '')
        const leadingZeros = digits.length - newDigits.length

        const svg = jackpotNumberElement.querySelector('svg')
        const allGElements = Array.from(svg.querySelectorAll('g'))

        for (let i = 0; i < leadingZeros; i++) {
            if (allGElements[i]) {
                allGElements[i].style.display = 'none'
            }
        }
    }
}
