import EventBus from '../core/EventBus'
import Component from '../core/Component'

const _states = {}

EventBus.on('modal:open', ({ id, url }) => {
    const state = {...window.history.state}

    state.url = state.url

    _states[state.url] = state

    const stateUrl = url ? url : `#${id}`

    if (!stateUrl || stateUrl === '#undefined') {
        return
    }

    history.replaceState({
        ...state,
        source: 'modal',
        modal: id
    }, state.title, state.url)

    history.pushState({
        source: 'modal',
        modal: id,
        url,
        type: 'instance'
    }, state.title, stateUrl)
})

EventBus.on('modal:close', ({ id }) => {
    document.title = history.state.title
    if (history?.state?.modal === id) {
        history.back()
    }

})

window.addEventListener('popstate', event => {
    const { source, modal: id, url, type } = event.state

    if (source !== 'modal' || !id) {
        return
    }

    if (url in _states) {
        const state = _states[url]
        history.replaceState(state, state.title, state.url)
        delete _states[url]
    }

    const modal = Component.getFromElement(id)
    if (modal && modal.isActive) {
        modal.close()
    }
})
