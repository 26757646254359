import Component from '../core/Component';
import debounce from 'lodash/debounce'

export const STATUS = {
    PLAYING: 'is-playing'
}

export default class Video extends Component {

    constructor(element) {
        super(element);

        this.ref = {
            video: null,
            button: null
        }

        this.playing = false;
    }

    prepare() {

        this.ref.video.addEventListener('play', event => {
            event.preventDefault()
            this.ref.video.setAttribute('controls', true);
            this.element.classList.add(STATUS.PLAYING);
            this.playing = true;
        });
        this.ref.video.addEventListener('pause', () => {
            this.element.classList.remove(STATUS.PLAYING);
            this.playing = false;
        });

        this.ref.video.addEventListener('click', () => {
            if (!this.playing) {
                this.ref.video.play();
            }
        });

        this.ref.button.addEventListener('click', () => {
            this.ref.video.play();
        });
    }
}