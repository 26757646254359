import Component from '../core/Component'
import PositionObserver from '../meta/PositionObserver'
import enquire from 'enquire.js'
import { queries } from '../core/config'

function toRad(deg) {
    return Math.PI / 180 * deg
}

const defaults = {
    parentSelector: '[data-chip-parent]',
    media: null
}

export default class ChipBackground extends Component {

    constructor(element) {
        super(element, defaults)

        this.raf = null
        this.ref = {
            chips: []
        }

        this.enquireHandler = {
            match: this.attach,
            unmatch: this.detach
        }

        if (this.element.dataset.media) {
            this.options.media = this.element.dataset.media
        }

        if (this.options.media) {
            this.options.media = queries?.[this.options.media] || this.options.media
        }
    }

    prepare() {
        if (this.options.media) {
            enquire.register(this.options.media, this.enquireHandler)
        } else {
            this.attach()
        }
    }

    destroy() {
        if (this.options.media) {
            enquire.unregister(this.options.media, this.enquireHandler)
        } else {
            this.detach()
        }
    }

    attach = () => {
        this.element.closest(this.options.parentSelector)?.classList.add('is-animated')

        this.observer = new PositionObserver(this.element, {
            onEnter: this.handleEnter,
            onLeave: this.handleLeave
        })

        this.chips = this.ref.chips.map(element => Component.getFromElement(element))
        this.chips.forEach(chip => chip.prepare())
        this.rotations = {
            chip1: {
                x: .08,
                y: 0,
                z: 0
            },
            chip2: {
                x: 0.07,
                y: 0.01,
                z: 0
            },
            chip3: {
                x: .2,
                y: 0.05,
                z: 0
            },
            chip4: {
                x: .2,
                y: 0.05,
                z: 0
            }
        }
    }

    detach = () => {
        this.element.closest(this.options.parentSelector)?.classList.remove('is-animated')
        if (this.observer) {
            this.observer.detach()
            this.observer = null
        }

        this.chips.forEach(chip => chip.destroy())
    }

    handleEnter = () => {
        this.start()
    }

    handleLeave = () => {
        this.stop()
    }

    start() {
        this.raf = requestAnimationFrame(this.render)
    }

    stop() {
        cancelAnimationFrame(this.raf)
        this.raf = null
    }

    render = () => {
        this.chips.forEach((chip, index) => {
            if (!chip.isReady) {
                return
            }
            chip.chip.rotation.x = toRad(chip.options.baseX + this.raf * this.rotations[chip.element.dataset.id].x)
            chip.chip.rotation.y = toRad(chip.options.baseY + this.raf * this.rotations[chip.element.dataset.id].y)
            chip.chip.rotation.z = toRad(chip.options.baseZ + this.raf * this.rotations[chip.element.dataset.id].z)
            chip.render()
        })
        this.raf = requestAnimationFrame(this.render)
    }

}
