import Component from '../core/Component'
import throttle from 'lodash/throttle'
import Scroll, { getScrollTop } from '../services/Scroll'

export const STATES = {
    STICKY: 'is-sticky'
}

export default class Sticky extends Component {
    constructor(element) {
        super(element)

        this.options = {
            top: 70
        }

        const top = parseInt(this.element.dataset.stickyTop)

        if (!isNaN(top)) {
            this.options.top = top
        }

        this.isSticky = false

        this.handleScroll = ::this.handleScroll
        this.handleResize = throttle(::this.handleResize, 100)
    }

    prepare() {
        this.parent = this.element.parentNode
        this.element.style.paddingTop = this.options.top + 'px'

        Scroll.on('scroll', this.handleScroll)
        Scroll.on('resize', this.handleResize)

        this.resize()
        this.render()
    }

    destroy() {
        Scroll.off('scroll', this.handleScroll)
        Scroll.off('resize', this.handleResize)
    }

    handleScroll() {
        this.render()
    }

    handleResize() {
        this.resize()
    }

    resize() {
        this.element.style.width = 'auto'

        const scrollTop = getScrollTop()

        const parentBox = this.parent.getBoundingClientRect()
        this.parentBox = {
            height: parentBox.height,
            width: parentBox.width,
            top: scrollTop + parentBox.top
        }

        const elementBox = this.element.getBoundingClientRect()
        this.elementBox = {
            height: elementBox.height,
            width: elementBox.width,
            top: scrollTop + elementBox
        }

        this.element.style.width = `${this.elementBox.width}px`
    }

    render() {
        var offset = getScrollTop() - this.parentBox.top + this.options.top
        var max = this.parentBox.height - this.elementBox.height
        if (offset < 0) {
            if (this.isSticky) {
                this.element.classList.remove(STATES.STICKY)
                this.isSticky = false
                this.element.style.transform = 'translateY(0px)'
            }
        } else if (offset > max) {
            if (this.isSticky) {
                this.element.classList.remove(STATES.STICKY)
                this.isSticky = false
                this.element.style.transform = `translateY(${max}px)`
            }
        } else {

            if (!this.isSticky) {
                this.element.classList.add(STATES.STICKY)
                this.isSticky = true
            }
            this.element.style.transform = `translateY(${offset}px)`
        }

    }
}