import { scrollToElement } from '../../services/Scroll';
import $ from 'jquery';
import { queryAll } from '../../utils/dom';

document.addEventListener('nette:valid', event => {
    const element = event.target;

    if (element.tagName.toLowerCase() != 'button') {
        const errorElement = element.parentNode.querySelector('.InputError');
        if (errorElement) {
            element.parentNode.removeChild(errorElement);
        }
    }

    element.classList.remove('has-error');
    element.classList.add('is-valid');
});

document.addEventListener('nette:invalid', event => {
    const element = event.target;

    let errorElement = element.parentNode.querySelector('.InputError');

    if (!errorElement) {
        errorElement = document.createElement('div');
        errorElement.className = 'InputError';
    }

    errorElement.innerHTML = element.getAttribute('data-nette-error');
    element.parentNode.appendChild(errorElement);
    element.classList.add('has-error');
    element.classList.remove('is-valid');
});

document.addEventListener('nette:form:valid', event => {
    console.info('Form valid', event.target);
    event.target.classList.add('is-sending');
});
document.addEventListener('nette:form:invalid', event => {
    const $form = $(event.target);
    const $error = $form.find('.InputError');
    let $target = $error.closest('.FormItem');
    scrollToElement($target.length ? $target[0] : $form[0]);
});

$.nette.ext('remove-is-sending-class', {
    success: (payload, status) => {
        queryAll('form').forEach(item => {
            item.classList.remove('is-sending');
        });
    }
});


$.nette.ext('scroll-to-reservation', {
    success: (payload, status) => {
        if (payload && payload.snippets && payload.snippets["snippet--reservation"]) {
            scrollToElement(document.getElementById('snippet--reservation'), -120);
        }
    }
});


