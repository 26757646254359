import Component from '../core/Component'
import Intersection from './Intersection'

export default class IntersectionGroup extends Component {

    constructor(element) {
        super(element)

        this.ref = {
            items: []
        }
    }

    prepare() {
        this.items = this.ref.items.map(element => new Intersection(element))
        this.items.forEach(item => item.prepare())
    }

    destroy() {
        this.items.forEach(item => item.destroy())
    }

}