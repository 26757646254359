import Component from '../core/Component'
import enquire from 'enquire.js'
import { queries } from '../core/config'

const ACTIVE = 'is-active'

export default class Hamburger extends Component {
    static States = {
        ACTIVE,
    }

    constructor(element) {
        super(element)

        this.enquireHandler = {
            match: this.reset,
        }
    }

    prepare() {
        this.element.addEventListener('click', this.handleClick)
        enquire.register(queries.largeUp, this.enquireHandler)
    }

    destroy() {
        enquire.unregister(queries.largeUp, this.enquireHandler)
    }

    reset = () => {
        this.element.classList.remove(ACTIVE)
    }

    handleClick = (event) => {
        event.preventDefault()
        this.element.classList.toggle(ACTIVE)

        const nav = Component.getFromElement(document.getElementById('nav'))

        if (nav) {
            nav.toggle({
                onClose: this.reset,
            })
        }
    }
}
