import Flickity from 'flickity'
import enquire from 'enquire.js'
import { queries } from '../core/config'
import Slider from './Slider'

const defaults = {
    cellAlign: window.matchMedia(queries.mediumMax).matches ? 'center' : 'left'
}

export default class PokerSlider extends Slider {
    constructor(element) {
        super(element, defaults)

        this.enquireHandler = {
            match: () => {
                this.destroySlider()
                this.options.cellAlign = 'center'
                this.createSlider()
            },
            unmatch: () => {
                this.destroySlider()
                this.options.cellAlign = 'left'
                this.createSlider()
            }
        }
    }

    prepare() {
        super.prepare()
        enquire.register(queries.mediumMax, this.enquireHandler)
    }

    destroy() {
        enquire.unregister(queries.mediumMax, this.enquireHandler)
        super.destroy()
    }

    shouldCreateSlider = () => {
        return this.ref.content.scrollWidth > this.ref.content.offsetWidth
    }

    resize() {
        if (!this.flickity && this.shouldCreateSlider()) {
            this.createSlider()
        }
        super.resize()
    }
}
