import Component from '../core/Component';
import { scrollToElement } from '../services/Scroll';

export default class FormScroll extends Component {

    prepare() {
        if ($.nette) {
            $.nette.ext('scroll', {
                success: function(data) {
                    const el = document.querySelector("#snippet--hotels")
                    if (el) {
                        scrollToElement(el)
                    }
                }
            });
        }
    }

    destroy() {
        if ($.nette) {
            $.nette.ext('scroll', null)
        }
    }

}