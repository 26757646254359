import Component from "../core/Component"

const POLL_INTERVAL_HYBERNATE = 2000
const POLL_INTERVAL = 3000

export const EMITS = {
    MESSAGE: "message",
}

export default class PokerStreamPoller extends Component {
    constructor(element, options = {}) {
        super(element, options)

        this.listenersCount = 0
        this.timer = null
    }

    prepare() {
        setTimeout(() => {
            this.start()
        }, 0)
    }

    destroy() {
        this.stop()
    }

    on(events, callback) {
        super.on(events, callback)
        this.listenersCount = this._callbacks[EMITS.MESSAGE].length
    }

    off(events, callback) {
        super.off(events, callback)
        this.listenersCount = this._callbacks[EMITS.MESSAGE].length
    }

    async run() {
        let interval = POLL_INTERVAL_HYBERNATE

        if (!document.hidden) {
            let hasActiveStreams = false
            this.timer = 1
            try {
                const response = await fetch(this.options.url)
                const json = await response.json()
                this.emit(EMITS.MESSAGE, json)

                hasActiveStreams =
                    json && json.streams && Object.keys(json.streams).length !== 0
            } catch (err) {
                console.error(err)
            }

            interval =
                hasActiveStreams && this.listenersCount !== 0
                    ? POLL_INTERVAL
                    : POLL_INTERVAL_HYBERNATE

            if (hasActiveStreams) {
                document.documentElement.classList.add('has-poker-streams')
            } else {
                document.documentElement.classList.remove('has-poker-streams')
            }
        }

        this.timer = setTimeout(() => {
            this.run()
        }, interval)
    }

    start() {
        if (this.timer) {
            return
        }
        this.run()
    }

    stop() {
        clearTimeout(this.timer)
        this.timer = null
    }
}
