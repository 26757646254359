import Component from '../core/Component'
import Scroll from '../services/Scroll'
import support from '../utils/BrowserSupport'

export default class HeroImage extends Component {
    constructor(element) {
        super(element)

        this.ref = {
            image: null
        }

        this.canRender = false
        this.box = null
    }

    prepare() {
        this.resize()
        Scroll.on('scroll', this.handleScroll)
        Scroll.on('resize', this.handleResize)
    }

    destroy() {
        Scroll.off('scroll', this.handleScroll)
        Scroll.off('resize', this.handleResize)
    }

    handleScroll = ({ offset }) => {
        this.render(offset.y)
    }

    handleResize = () => {
        this.resize()
    }

    resize() {
        const box = this.element.getBoundingClientRect()
        const offset = Scroll.getScroll()

        this.box = {
            top: box.top - offset.y,
            left: box.left,
            right: box.right,
            width: box.width,
            height: box.height
        }
    }

    render(scrollTop) {
        const ratio = scrollTop/(this.box.top + this.box.height)

        if (ratio > 1 && this.canRender) {
            this.canRender = false
        }

        if (ratio < 1 && !this.canRender) {
            this.canRender = true
        }

        if (!this.canRender) {
            return
        }

        this.ref.image.style[support.transform] = `translate3d(0, ${ratio * this.box.height * 0.15}px, 0)`
    }
}