import Component from '../core/Component';

export default class VisualPage extends Component {
    constructor(element) {
        super(element);

        this.ref = {
            errorVideo: null,
        };
    }

    prepare() {
        this.ref.errorVideo.playbackRate = .4;
        this.ref.errorVideo.play();
        setTimeout(() => {
            this.ref.errorVideo.play();
        }, 400);
    }

}