import Component from '../core/Component'
import { disableScrolling, enableScrolling } from '../services/Scroll'
import support from '../utils/BrowserSupport'
import enquire from 'enquire.js'
import { queries } from '../core/config'

export const STATES = {
    ACTIVE: 'is-active',
    VISIBLE: 'is-visible'
}

export default class Nav extends Component {

    constructor(element) {
        super(element)

        this.ref = {
            content: null
        }

        this.enquireHandler = {
            match: () => {
                this.hide();
            }
        }
    }

    prepare() {
        this.element.addEventListener(support.transitionEnd, () => {
            if (!this.is(STATES.ACTIVE)) {
                this.element.classList.remove(STATES.VISIBLE)
            }
        })

        this.element.addEventListener('click', ::this.handleClick)

        enquire.register(queries.largeUp, this.enquireHandler)
    }

    destroy() {
        document.body.classList.remove('is-mobile-nav-active')
        enquire.unregister(queries.largeUp, this.enquireHandler)
    }

    handleClick(event) {
        if (!this.ref.content.contains(event.target)) {
            this.hide()
        }

        if (event.target.closest('a')) {
            this.hide()
        }
    }

    show() {
        if (this.is(STATES.ACTIVE)) {
            return
        }
        this.element.classList.add(STATES.VISIBLE)
        this.element.offsetWidth
        this.element.classList.add(STATES.ACTIVE)
        document.body.classList.add('is-mobile-nav-active')
        disableScrolling()
    }

    hide() {
        if (this.is(STATES.ACTIVE)) {
            this.element.classList.remove(STATES.ACTIVE)
            document.body.classList.remove('is-mobile-nav-active')
            enableScrolling()
        }
    }

    toggle(options) {

        if (this.is(STATES.ACTIVE)) {
            this.hide()
        } else {
            this.show()
        }
    }
}
