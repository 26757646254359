import Component from '../core/Component';
import Sticky from './Sticky';

export default class Partnership extends Component {

    constructor(element) {
        super(element)

        this.ref = {
            toggler: null,
        }
    }

    prepare() {
        this.ref.toggler.addEventListener('click', ::this.handleClick)
    }

    destroy() {
        this.ref.toggler.removeEventListener('click',::this.handleClick)
    }

    handleClick(event) {
        event.preventDefault()

        this.element.classList.toggle('is-open')
    }
}