import Component from '../core/Component'
import { USE_NATIVE_SCROLL, queries } from '../core/config'
import Scrollbar from 'smooth-scrollbar'
import enquire from 'enquire.js'

export const defaults = {
    renderByPixels: true,
    alwaysShowTracks: true,
    media: null
}

export default class ScrollContainer extends Component {
    constructor(element, options = {}) {
        super(element, defaults)

        this.options = options

        this.enquireHandler = {
            match: this.attach,
            unmatch: this.detach
        }

        if (this.options.media in queries) {
            this.options.media = queries[this.options.media]
        }
    }

    prepare() {
        if (this.options.media) {
            enquire.register(this.options.media, this.enquireHandler)
        } else {
            this.attach()
        }
    }

    destroy() {
        this.detach()
    }

    attach = () => {
        if (!USE_NATIVE_SCROLL) {
            this.scrollbar = Scrollbar.init(this.element, this.options)
        }
    }

    detach = () => {
        if (this.scrollbar) {
            this.scrollbar.destroy(this.element)
            this.element.removeAttribute('data-scrollbar')
        }
    }
}
