import Component from '../core/Component'
import Flickity from 'flickity'
import GameCard from './GameCard'

export const STATES = {
    ACTIVE: 'is-active'
}

export default class GameCarousel extends Component {

    constructor(element) {
        super(element)

        this.ref = {
            cards: [],
            content: null,
            dots: []
        }

        this.cards = []
    }

    prepare() {
        this.flickity = new Flickity(this.ref.content, {
            prevNextButtons: false,
            selectedAttraction: 0.2,
            friction: 0.8,
            pageDots: false
        })

        this.cards = this.ref.cards.map(card => new GameCard(card))
        this.cards.forEach(card => card.prepare())

        this.ref.dots.forEach((dot, index) => dot.addEventListener('click', () => this.handleClick(index)))

        this.flickity.on('scroll', ::this.handleScroll)
        this.flickity.on('change', ::this.handleChange)

        this.render(0)
    }

    destroy() {
        this.cards.forEach(card => card.destroy())
    }

    handleScroll(progress) {
        this.render(progress)
    }

    handleChange(current) {
        this.ref.dots
            .filter((dot, index) => index !== current)
            .forEach(dot => dot.classList.remove(STATES.ACTIVE))
        this.ref.dots[current].classList.add(STATES.ACTIVE)
    }

    handleClick(index) {
        this.flickity.select(index)
    }

    render(progress) {
        const count = this.cards.length - 1

        progress = Math.min(Math.max(progress, 0), 1)
        this.flickity.slides.forEach((slide, index) => {
            const current = progress * count - index

            const ratio = Math.max(1 - Math.abs(0.5 - ( 0.5 + current * count )) * .3, 0)
            this.cards[index].render(ratio)
        });
    }

}