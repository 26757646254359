import Component from '../core/Component';

export default class Toggle extends Component {

    constructor(element) {
        super(element);

        this.ref = {
            link: null,
            content: null
        }
    }

    prepare() {
        this.ref.link.addEventListener("click",::this.handleClick)
    }

    handleClick(e) {
        e.preventDefault();

        const link = e.currentTarget
        
        if (link.getAttribute("data-text-swap") == link.innerHTML) {
          link.innerHTML = link.getAttribute("data-text-original");
          link.classList.add("is-active")
        } else {
          link.setAttribute("data-text-original", link.innerHTML);
          link.innerHTML = link.getAttribute("data-text-swap");
        }

        this.ref.content.classList.toggle("is-active")
    }

}