import enquire from 'enquire.js';

import { queries } from '../core/config';
import Slider from './Slider';

const defaults = {
    cellAlign: window.matchMedia(queries.mediumMax).matches ? 'center' : 'left',
    pageDots: true
}

export default class CardSlider extends Slider {
    constructor(element) {
        super(element, defaults)

        this.enquireHandler = {
            match: () => {
                this.prepareSlider()
            },
            unmatch: () => {
                this.destroySlider()
            }
        }
    }

    prepare() {
        enquire.register(queries.smallWideMax, this.enquireHandler)
    }

    prepareSlider() {
        super.prepare()
        this.options.cellAlign = 'center'
    }

    destroy() {
        enquire.unregister(queries.smallWideMax, this.enquireHandler)
        super.destroy()
    }

}
