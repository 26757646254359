import Emitter from '../../core/Emitter'
import {
    getScrollTop as getScrollTopNative,
    getScroll as getScrollNative,
    enableScrolling as enableScrollingNative,
    disableScrolling as disableScrollingNative,
} from '../Viewport/scroll'
import scrollToNative from '../Viewport/scrollTo'
import throttle from 'lodash/throttle'
import Scrollbar, { ScrollbarPlugin } from 'smooth-scrollbar'
import TouchMouseInputResolver from '../../meta/TouchMouseInputResolver'

import { default as scrollToElementNative } from '../Viewport/scrollToElement'

class ScrollDisabler extends ScrollbarPlugin {
    static pluginName = 'ScrollDisabler'

    static defaultOptions = {
        disabled: false,
    }

    transformDelta(delta) {
        if (this.options.disabled) {
            return {
                x: 0,
                y: 0,
            }
        }

        return delta
    }
}

Scrollbar.use(ScrollDisabler)

class Scroll extends Emitter {
    constructor(context = window) {
        super()

        this.context = context
        this.current = {
            x: 0,
            y: 0,
        }
        this.raf = null
        this.container = document.querySelector('.scroll-Container')

        this.handleResize = throttle(this.handleResize, 50)

        window.addEventListener('resize', this.handleResize)
        window.addEventListener('scroll', this.handleNativeScroll)

        this.scrollbar = null
        this.isVirtual = false
    }

    getScroll() {
        if (this.isVirtual) {
            return {
                x: this.scrollbar.scrollLeft,
                y: this.scrollbar.scrollTop,
            }
        } else {
            return getScrollNative()
        }
    }

    handleNativeScroll = () => {
        if (this.isVirtual) {
            return
        }

        this.current = getScrollNative()

        this.render()
    }

    handleResize = () => {
        this.resize()
    }

    handleVirtualScroll = (status) => {
        this.current = {
            x: status.offset.x,
            y: status.offset.y,
        }

        this.render()
    }

    render = () => {
        this.emit('scroll', {
            offset: { ...this.current },
        })
    }

    resize = () => {
        this.emit('resize')
    }

    setPosition(x, y) {
        if (this.isVirtual) {
            this.scrollbar.setPosition(x, y)
        } else {
            document.body.scrollLeft = x
            document.body.scrollTop = y
        }
    }

    useNative() {
        this.isVirtual = false
        this.container.classList.remove('is-virtual')
    }

    useVirtual() {
        this.isVirtual = true
        this.container.classList.add('is-virtual')

        if (!this.scrollbar) {
            this.scrollbar = Scrollbar.init(this.container, {
                renderByPixels: false,
            })
            this.scrollbar.addListener(this.handleVirtualScroll)
        }
    }
}

const scrollInstance = new Scroll()

export function scrollToElement(element, options = { offset: 80 }) {
    if (scrollInstance.isVirtual) {
        scrollInstance.scrollbar.scrollIntoView(element, {
            offsetTop: options.offset ? options.offset : 0,
            onlyScrollIfNeeded: options.loose,
            alignToTop: true,
        })
    } else {
        scrollToElementNative(element, options)
    }
}

export function scrollTo(offset, options) {
    if (scrollInstance.isVirtual) {
        scrollInstance.scrollbar.scrollTo(0, offset, 600, options)
    } else {
        scrollToNative(offset, options)
    }
}

export function getScroll() {
    if (scrollInstance.isVirtual) {
        return scrollInstance.scrollbar.offset
    } else {
        return getScrollNative()
    }
}

export function getScrollTop() {
    if (scrollInstance.isVirtual) {
        return scrollInstance.scrollbar.scrollTop
    } else {
        return getScrollTopNative()
    }
}

export function disableScrolling() {
    if (scrollInstance.isVirtual) {
        scrollInstance.scrollbar.updatePluginOptions('ScrollDisabler', {
            disabled: true,
        })
    } else {
        disableScrollingNative()
    }
}

export function enableScrolling() {
    if (scrollInstance.isVirtual) {
        scrollInstance.scrollbar.updatePluginOptions('ScrollDisabler', {
            disabled: false,
        })
    } else {
        enableScrollingNative()
    }
}

export { disableScrolling as disableScroll }
export { enableScrolling as enableScroll }

window.scrollInstance = scrollInstance

export default scrollInstance
