import Component from '../core/Component'
import support from '../utils/BrowserSupport'
import throttle from 'lodash/throttle'

const easeOutCubic = t => (--t)*t*t+1
const easeOutExpo = t => (t===1) ? 1 : -Math.pow(2, -10 * t) + 1

export const STATES = {
    READY: 'is-ready'
}

export default class GameCard extends Component {

    constructor(element) {
        super(element)

        this.ref = {
            image: null,
            footer: null,
            photo: null
        }

        this.progress = -1
        this.handleResize = throttle(::this.handleResize, 200)
    }

    prepare() {
        window.addEventListener('resize', this.handleResize)
        this.handleResize()
        this.element.classList.add(STATES.READY)
    }

    destroy() {
        window.removeEventListener('resize', this.handleResize)
    }

    handleResize() {
        this.ref.photo.style.width = `${this.element.offsetWidth}px`
    }

    render(progress) {
        progress = Math.round(progress * 100)/100
        if (progress === this.progress) {
            return
        }

        this.progress = progress
        this.ref.photo.style[support.transform] = `translate(-50%, ${30 - progress * 30}px)`
        this.ref.image.style.width = `${progress * 150}%`
        this.ref.image.style.height = `${progress * 300}%`
        this.ref.footer.style[support.transform] = `translateY(${progress * 100}%)`
    }
}