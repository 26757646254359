import Component from '../core/Component'
import EventBus from '../core/EventBus'
import { createModal } from './Modal'

const CMS = document.body.classList.contains('is-cms')

export default class ModalOpener extends Component {

    prepare() {
        this.element.addEventListener('click', this.handleClick);
    }

    handleClick = event => {
        event.preventDefault()
        console.log(event)

        if (CMS) {
            return
        }

        let id = this.element.dataset.modalId || this.element.getAttribute('href')

        if (!id) {
            return;
        }

        if (!this.element.dataset.modalId && id.indexOf('#') !== 0) {
            this.dispatchModal({
                ...this.options,
                url: this.element.getAttribute('href'),
                filterResponse: function(html) {
                    const buffer = document.createElement('div')
                    buffer.innerHTML = html
                    const content = buffer.querySelector('[data-modal-content]')
                    if (content) {
                        return content.outerHTML
                    }
                    return html
                }
            })
            return
        }

        id = id.replace('#', '')

        const modal = Component.getFromElement(id)

        console.log(id)

        if (modal) {
            const data = JSON.parse(this.element.dataset.data || '{}')
            modal.open(data)
            EventBus.emit('modalopener:click', {
                id: id,
                element: this.element,
                data
            })
        }

    }

    dispatchModal(options = {}) {
        createModal(options)
    }
}
