import Component from '../core/Component'

export default class ShowDatePicker extends Component {
    prepare() {
        this.element.addEventListener('click', this.handleClick)
    }

    handleClick = () => {
        const field = Component.getFromElement(this.element.dataset.fieldId)
        field?.focus()
    }
}
